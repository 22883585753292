<template >
  <div></div>
</template>

<script>

export default {
  data() {
    return {
      errors: {
        code: '',
        status: '',
        message: '',
        headers: ''
      }
    }
  },
  created() {
    this.$store.dispatch('destroyToken')
    .then(() => {
      this.$router.push({name: 'Login'})
      this.$toasted.success('Logout successful!')
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
}
</script>
